/**
 * Get the outer sizes of the given element (offset size + margins)
 * @method
 * @memberof Popper.Utils
 * @argument {Element} element
 * @returns {Object} object containing width and height properties
 */
export default function getOuterSizes(element) {
 const styles = window.getComputedStyle(element);
 const x = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
 const y = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight);
 const result = {
 width: element.offsetWidth + y,
 height: element.offsetHeight + x,
 };
 return result;
}
