import modifiers from '../modifiers/index';

/**
 * Default options provided to Popper.js constructor.<br />
 * These can be overriden using the `options` argument of Popper.js.<br />
 * To override an option, simply pass as 3rd argument an object with the same
 * structure of this object, example:
 * ```
 * new Popper(ref, pop, {
 * modifiers: {
 * preventOverflow: { enabled: false }
 * }
 * })
 * ```
 * @type {Object}
 * @static
 * @memberof Popper
 */
export default {
 /**
 * Popper's placement
 * @prop {Popper.placements} placement='bottom'
 */
 placement: 'bottom',

 /**
 * Whether events (resize, scroll) are initially enabled
 * @prop {Boolean} eventsEnabled=true
 */
 eventsEnabled: true,

 /**
 * Set to true if you want to automatically remove the popper when
 * you call the `destroy` method.
 * @prop {Boolean} removeOnDestroy=false
 */
 removeOnDestroy: false,

 /**
 * Callback called when the popper is created.<br />
 * By default, is set to no-op.<br />
 * Access Popper.js instance with `data.instance`.
 * @prop {onCreate}
 */
 onCreate: () => {},

 /**
 * Callback called when the popper is updated, this callback is not called
 * on the initialization/creation of the popper, but only on subsequent
 * updates.<br />
 * By default, is set to no-op.<br />
 * Access Popper.js instance with `data.instance`.
 * @prop {onUpdate}
 */
 onUpdate: () => {},

 /**
 * List of modifiers used to modify the offsets before they are applied to the popper.
 * They provide most of the functionalities of Popper.js
 * @prop {modifiers}
 */
 modifiers,
};

/**
 * @callback onCreate
 * @param {dataObject} data
 */

/**
 * @callback onUpdate
 * @param {dataObject} data
 */
