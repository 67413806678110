/**
 * Tells if you are running Internet Explorer 10
 * @method
 * @memberof Popper.Utils
 * @returns {Boolean} isIE10
 */
let isIE10 = undefined;

export default function() {
 if (isIE10 === undefined) {
 isIE10 = navigator.appVersion.indexOf('MSIE 10') !== -1;
 }
 return isIE10;
}
