/**
 * Remove event listeners used to update the popper position
 * @method
 * @memberof Popper.Utils
 * @private
 */
export default function removeEventListeners(reference, state) {
 // Remove resize event listener on window
 window.removeEventListener('resize', state.updateBound);

 // Remove scroll event listener on scroll parents
 state.scrollParents.forEach(target => {
 target.removeEventListener('scroll', state.updateBound);
 });

 // Reset state
 state.updateBound = null;
 state.scrollParents = [];
 state.scrollElement = null;
 state.eventsEnabled = false;
 return state;
}
