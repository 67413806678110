import setStyles from '../utils/setStyles';
import setAttributes from '../utils/setAttributes';
import getReferenceOffsets from '../utils/getReferenceOffsets';
import computeAutoPlacement from '../utils/computeAutoPlacement';

/**
 * @function
 * @memberof Modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @argument {Object} data.styles - List of style properties - values to apply to popper element
 * @argument {Object} data.attributes - List of attribute properties - values to apply to popper element
 * @argument {Object} options - Modifiers configuration and options
 * @returns {Object} The same data object
 */
export default function applyStyle(data) {
 // any property present in `data.styles` will be applied to the popper,
 // in this way we can make the 3rd party modifiers add custom styles to it
 // Be aware, modifiers could override the properties defined in the previous
 // lines of this modifier!
 setStyles(data.instance.popper, data.styles);

 // any property present in `data.attributes` will be applied to the popper,
 // they will be set as HTML attributes of the element
 setAttributes(data.instance.popper, data.attributes);

 // if arrowElement is defined and arrowStyles has some properties
 if (data.arrowElement && Object.keys(data.arrowStyles).length) {
 setStyles(data.arrowElement, data.arrowStyles);
 }

 return data;
}

/**
 * Set the x-placement attribute before everything else because it could be used
 * to add margins to the popper margins needs to be calculated to get the
 * correct popper offsets.
 * @method
 * @memberof Popper.modifiers
 * @param {HTMLElement} reference - The reference element used to position the popper
 * @param {HTMLElement} popper - The HTML element used as popper.
 * @param {Object} options - Popper.js options
 */
export function applyStyleOnLoad(
 reference,
 popper,
 options,
 modifierOptions,
 state
) {
 // compute reference element offsets
 const referenceOffsets = getReferenceOffsets(state, popper, reference);

 // compute auto placement, store placement inside the data object,
 // modifiers will be able to edit `placement` if needed
 // and refer to originalPlacement to know the original value
 const placement = computeAutoPlacement(
 options.placement,
 referenceOffsets,
 popper,
 reference,
 options.modifiers.flip.boundariesElement,
 options.modifiers.flip.padding
 );

 popper.setAttribute('x-placement', placement);

 // Apply `position` to popper before anything else because
 // without the position applied we can't guarantee correct computations
 setStyles(popper, { position: 'absolute' });

 return options;
}
