/**
 * @function
 * @memberof Modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @argument {Object} options - Modifiers configuration and options
 * @returns {Object} The data object, properly modified
 */
export default function shift(data) {
 const placement = data.placement;
 const basePlacement = placement.split('-')[0];
 const shiftvariation = placement.split('-')[1];

 // if shift shiftvariation is specified, run the modifier
 if (shiftvariation) {
 const { reference, popper } = data.offsets;
 const isVertical = ['bottom', 'top'].indexOf(basePlacement) !== -1;
 const side = isVertical ? 'left' : 'top';
 const measurement = isVertical ? 'width' : 'height';

 const shiftOffsets = {
 start: { [side]: reference[side] },
 end: {
 [side]: reference[side] + reference[measurement] - popper[measurement],
 },
 };

 data.offsets.popper = { ...popper, ...shiftOffsets[shiftvariation] };
 }

 return data;
}
