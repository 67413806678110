/**
 * Get the opposite placement variation of the given one
 * @method
 * @memberof Popper.Utils
 * @argument {String} placement variation
 * @returns {String} flipped placement variation
 */
export default function getOppositeVariation(variation) {
 if (variation === 'end') {
 return 'start';
 } else if (variation === 'start') {
 return 'end';
 }
 return variation;
}
