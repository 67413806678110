/**
 * Mimics the `find` method of Array
 * @method
 * @memberof Popper.Utils
 * @argument {Array} arr
 * @argument prop
 * @argument value
 * @returns index or -1
 */
export default function find(arr, check) {
 // use native find if supported
 if (Array.prototype.find) {
 return arr.find(check);
 }

 // use `filter` to obtain the same behavior of `find`
 return arr.filter(check)[0];
}
