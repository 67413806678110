import isModifierEnabled from '../utils/isModifierEnabled';
import getSupportedPropertyName from '../utils/getSupportedPropertyName';

/**
 * Destroy the popper
 * @method
 * @memberof Popper
 */
export default function destroy() {
 this.state.isDestroyed = true;

 // touch DOM only if `applyStyle` modifier is enabled
 if (isModifierEnabled(this.modifiers, 'applyStyle')) {
 this.popper.removeAttribute('x-placement');
 this.popper.style.left = '';
 this.popper.style.position = '';
 this.popper.style.top = '';
 this.popper.style[getSupportedPropertyName('transform')] = '';
 }

 this.disableEventListeners();

 // remove the popper if user explicity asked for the deletion on destroy
 // do not use `remove` because IE11 doesn't support it
 if (this.options.removeOnDestroy) {
 this.popper.parentNode.removeChild(this.popper);
 }
 return this;
}
