/**
 * Set the attributes to the given popper
 * @method
 * @memberof Popper.Utils
 * @argument {Element} element - Element to apply the attributes to
 * @argument {Object} styles
 * Object with a list of properties and values which will be applied to the element
 */
export default function setAttributes(element, attributes) {
 Object.keys(attributes).forEach(function(prop) {
 const value = attributes[prop];
 if (value !== false) {
 element.setAttribute(prop, attributes[prop]);
 } else {
 element.removeAttribute(prop);
 }
 });
}
